<template>
  <ion-page class="ion-page">
    <GoBackHeader defaultHref="/profile" />

    <ion-content>
      <Refresher @refresh="doRefresh($event, 'orders')" />

      <List
        ref="slidingList"
        :header="$t('orders')"
        :loading="$apollo.loading && !orders"
        :loading-more="loadingMore"
        :not-found-message="$t('noordersfound')"
        @loadMore="loadMore($event, 'orders')"
      >
        <template v-if="orders && orders.data && orders.data.length">
          <ion-item-sliding v-for="order in orders.data" :key="order.id">
            <Order :order="order"/>
          </ion-item-sliding>
        </template>
      </List>
    </ion-content>
  </ion-page>
</template>

<script>
  import GoBackHeader from "@/components/navigation/GoBackHeader";
  import { GET_ORDERS } from '../../graphql/queries';
  import { infiniteScrollMixin } from '@/mixins';
  import { defaultPaginationConfig } from '@/configs';
  import Order from "./Order";

  export default {
    components: {
      GoBackHeader,
      Order,
    },
    mixins: [
      infiniteScrollMixin,
    ],
    data() {
      return {
        error: null,
      };
    },
    computed: {
      user() {
        return this.$store.getters.currentUser;
      },
    },
    methods: {
      async doRefresh(event, model) {
        this.resetInfiniteScroll();
        await this.$apollo.queries[model].refetch();
        event.target.complete()
      },
    },
    apollo: {
      orders: {
        query: GET_ORDERS,
        variables: {
          ...defaultPaginationConfig,
        }
      }
    },
  };
</script>
