<template>
  <ion-item>
    <ion-label>
      <ion-text>
        <h2>{{ order.name }}</h2>
      </ion-text>
      <ion-text color="moto">
        <h3>{{ order.seller.name }}</h3>
      </ion-text>

      <ion-list>
        <div v-for="(eventGroup, i) in eventGroups" :key="i" class="mb-5">
          <ion-text>
            <h2>{{ eventGroup.event.title }}</h2>
          </ion-text>
          <ion-text color="moto">
            <h3>{{ $t('heldat') }}: {{ eventGroup.event.held_at | formatDateTime }}</h3>
          </ion-text>

          <template v-for="(group, idx) in eventGroup.groupedTickets">
            <ion-text class="mt-2 block" :key="idx">
              <template v-if="group.is_refunded">
                <s>
                  <h3><ion-icon src="/icons/ticket.svg" color="moto" /> {{ group.items.length }}x {{ group.items[0].name }}</h3>
                </s>
              </template>
              <h3 v-else><ion-icon src="/icons/ticket.svg" color="moto" /> {{ group.items.length }}x {{ group.items[0].name }}</h3>
            </ion-text>
          </template>

          <template v-for="(group, idx) in eventGroup.groupedProducts">
            <ion-text class="mt-2 block" :key="idx">
              <template v-if="group.is_refunded">
                <s>
                  <h3><ion-icon src="/icons/product.svg" color="moto" /> {{ group.items.length }}x {{ group.items[0].productVariant.name }}</h3>
                </s>
              </template>
              <h3 v-else><ion-icon src="/icons/product.svg" color="moto" /> {{ group.items.length }}x {{ group.items[0].productVariant.name }}</h3>
            </ion-text>
          </template>
        </div>
      </ion-list>

      <ion-text class="mt-2 block">
        <h3>{{ $t('orderedat') }}: {{ order.created_at | toLocalTime | formatDateTime }}</h3>
      </ion-text>
      <ion-badge v-if="stripe_status.paid === order.stripe_status" color="moto" class="mt-2">
        {{ $t('paidstatus') }}
      </ion-badge>
      <ion-badge v-else-if="stripe_status.cancelled === order.stripe_status" color="danger" class="mt-2">
        {{ $t('cancelledstatus') }}
      </ion-badge>
      <ion-badge v-else-if="stripe_status.open === order.stripe_status" color="warning" class="mt-2">
        {{ $t('openstatus') }}
      </ion-badge>
      <ion-badge v-else-if="stripe_status.refunded === order.stripe_status" color="medium" class="mt-2">
        {{ $t('refundedstatus') }}
      </ion-badge>
      <ion-badge v-else-if="stripe_status.partially_refunded === order.stripe_status" color="medium" class="mt-2">
        {{ $t('partiallyrefundedstatus') }}
      </ion-badge>
    </ion-label>

    <div slot="end">
      <ion-badge color="moto">
        {{ order.total_price + order.rider_fee | stripeAmount }}
      </ion-badge>
      <ion-label class="mt-4" v-if="[stripe_status.partially_refunded, stripe_status.refunded].includes(order.stripe_status)">
        {{ $t('refunded') }}
      </ion-label>
      <ion-badge color="medium" class="mt-2" v-if="[stripe_status.partially_refunded, stripe_status.refunded].includes(order.stripe_status)">
        {{ order.refunded_amount | stripeAmount }}
      </ion-badge>
    </div>
  </ion-item>
</template>

<script>
  import { StripeStatus } from "@/graphql/enums";
  import { groupOrderablesByEvent } from "@/utils";

  export default {
    props: ['order'],
    data() {
      return {
        stripe_status: StripeStatus
      }
    },
    computed: {
      eventGroups() {
        if (!this.order) {
          return [];
        }

        // eslint-disable-next-line no-unreachable
        return groupOrderablesByEvent([this.order]);
      },
    }
  }
</script>
